import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import params from "../../shared/utilities/global_parameters";

export const TCText = [
  {
    id: "T&Cs",
    text: (
      <Fragment>
        <h1>Terms and Conditions</h1>
        <p>
          By using the {params.app_name_short}, you agree you have read and
          understood the Terms & Conditions set out below.
        </p>
        <h2>1. Use of this {params.app_name_short}</h2>
        <p>
          The {params.app_name_short} is solely for guidance purposes and
          provides estimates only. It is not intended to provide financial advice.
          If you require financial advice, you should contact an independent
          financial adviser. None of the Government Actuary’s Department (GAD),
          your employer or your Pension Scheme and its administrators have or
          accept any liability for financial decisions taken based on the 
          information provided in the {params.app_name_short}.
        </p>
        <p>
          The results shown are an indication of the benefits you may receive;
          they are not guaranteed and you should not enter into financial
          commitments on the basis of the information provided.
        </p>
        <p>
          The {params.app_name_short} does not override the scheme rules or
          pension legislation.
        </p>
        <h2>2. How your pension and lump sum have been estimated</h2>
        <h3>
          2.1. Comparison of pre and post reform of benefits
        </h3>
        <p>
        The results section shows a comparison of pension and lump 
        sum benefits that might be received both pre and post reform of benefits. 
        Member contributions have not been included in the illustration.
        </p>
        <h3>2.2. Assumptions</h3>
        <p>
        The results which show pension and lump sum estimates are based on the
        following key assumptions:
        </p>
        <ul>
          <li>
          Your total reformed pension is adjusted in line with prices (CPI) every year that you are an active member of the scheme
          </li>
          <li> 
          Salaries are assumed to increase at a rate of CPI + 1.5% per annum.  This is only an assumption for illustration purposes and does not mean that salaries will be increased at such rate in practice.  
          </li>
        </ul>
        <h3>2.3. Cash lump sum</h3>
        <p>
        At retirement, you will get an automatic lump sum in respect of your pre-reform
         benefits. This is three times your pre-reform pension. You are eligible to
        commute pension (exchange pension for a one-off lump sum) from both your
        pre and post-reform benefits, subject to certain terms and conditions.
        This calculator uses illustrative unisex commutation factors (the exchange rate of pension to lump sum).
        However, commutation factors for the pre-reform benefits are reviewed periodically 
        and the amount of cash
        lump sum available at your actual retirement will depend on the commutation
        factors in effect at that time. The commutation factors for your post-reform
        benefits are fixed.  Further details on the commutation options modelled can
        be found via the ‘Help’ button in the lump sum section of the results page.
        </p>
        <h2>3. Taxation</h2>
        <p>
          The illustrations do not allow for taxation. Your benefits will be
          subject to the various tax rates and limits in force.
        </p>
        <h2>4. Other</h2>
        <p>
        The results do not include any money purchase Additional Voluntary Contributions 
        (AVCs) benefits which you may have purchased, pension debits or other special 
        arrangements within the schemes.
        </p>

        <p>
        The calculator does not show pension from other sources, for example the state 
        pension or other private arrangements you may have.
        </p>

        <p>
        Your scheme provides survivor benefits payable in the event of your death, 
        which are not shown here. See your scheme guide for details. Your benefit statement 
        may also provide further information.
        </p>
        
        <p>
        No allowance has been made for guaranteed minimum pension (GMP) and any impact that 
        GMP has on when benefits can be taken.
        </p>
      </Fragment>
    ),
  },
];

export const SmallPrintText = [
  {
    id: "SP",
    text: (
      <Fragment>
        <h2>The Small Print</h2>
        {/* <p>
          The {params.app_name_long} is currently available as a pilot.
        </p> */}
        <p>
          For the best experience complete the {params.app_name_long} on a
          desktop computer. The {params.app_name_short} will not check your information 
          against other systems. If you enter inaccurate information, the outputs 
          will be of limited value.
        </p>
        <p>
          No data you enter into this {params.app_name_short} is stored or
          otherwise processed.
        </p>
        <p>
          By using this {params.app_name_short}, you agree that you have read
          and understood the Terms & Conditions as set out in the{" "}
          <Link to="/T&Cs">T&Cs' tab</Link>.
        </p>
        <p>
          This {params.app_name_short} is for guidance and illustration purposes
          only. The {params.app_name_short} is designed to provide a basic 
          illustration of your projected pension and lump sum at retirement for
          both your pre- and post-reformed benefits – other aspects such as tax
          debits, early or late retirement etc are explicitly not covered. If you
          have these or similar additional options, the illustrator will be less
          accurate (though it may still be helpful). This {params.app_name_short}
          is not intended to provide you with financial advice. If you require
          financial advice, you could consider using an independent financial
          adviser.
        </p>
        <p>The assumptions we have used are:</p>
        <table>
          <tbody>
            <tr>
              <td>Earnings inflation </td>
              <td>CPI + 1.5%</td>
            </tr>
          </tbody>
        </table>
        <p>
          For the comparison to make sense, we need to project your benefits.
          Therefore, the figures we show will not be equal to those in your
          Annual Benefit Statement.
        </p>
        <p>
          Where we project benefits in this {params.app_name_short}, we present
          figures in <b>today's money terms</b>. <span style = {{'fontStyle' : 'italic'}}> "Today's money terms" </span> means the 
          pension values are adjusted for inflation and therefore will represent what 
          this pension would be worth today, i.e. so you can compare it with your 
          income today and what you expect to need in retirement.
        </p>
      </Fragment>
    ),
  },
];

export const IntroText = [
  {
    id: "IT",
    text: (
      <Fragment>
        <h2>Welcome to the {params.app_name_long}</h2>
        <p>
        The {params.app_name_long} is for members impacted by the reforms in the
        Combined Nuclear Pension Plan (CNPP) and the Magnox Electric Group of
        the Electricity Supply Pension Scheme (Magnox ESPS). You can find further
        information on the reforms, including who is and is not in scope at 
        the following link: <Link to = "https://ndagrouppensionreform.co.uk/"> NDA group pension reform</Link> 
        </p>

        <p>
        This {params.app_name_short} is designed to provide members with an 
        overview of how the reforms will impact their pension benefits. It 
        allows you to make an approximate comparison based on your inputs and
        certain assumptions about what happens in the future. It does not 
        allow for all circumstances and nor does it take account of you 
        overall financial position or other pensions and savings arrangements
        which you may have.
        </p>
      </Fragment>
    ),
  },
];
