import React, { Fragment, useState, useEffect } from "react";
import InfoModal from "../../general/InfoModal";

import classes from "./ControlSwitches.module.css";

const LumpSumSwitch = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [equalisation_impossible, set_equalisation_impossible] = useState(false);

  useEffect(
    () => {

      if(props.commutation_percentage === 'Equal' && props.equalisation_outcome === 'impossible') {
        
        set_equalisation_impossible(true)
      }
    },
    [props.equalisation_outcome, props.commutation_percentage]
  )

  const modalHandler = () => {
    setShowModal(!showModal);
  };

  const infoMessage = (
    <Fragment>
      <h2>Lump Sums</h2>
      <p>
      At retirement you will get an automatic lump sum of three times your pre-reform scheme pension and you will be able to choose how much pension to commute for a lump sum (within limits) from each of the schemes. The options available provide the following benefits:
      </p>
      <p>
        <ul>
          <li><strong>None</strong> – it is assumed no commutation (exchange of pension for a one-off lump sum) is taken in either the pre-reform scheme or reformed scheme beyond any automatic lump sum from the pre-reform scheme. </li>
          <li><strong>Equal</strong> - it is assumed that commutation is taken from both the pre-reformed and reformed scheme at the same proportion of the remaining headroom available below the HMRC limit such that total lump sums are equal in both the pre and post reform scenarios including any automatic lump sum from the pre-reform scheme. </li>
          <li><strong>Maximum</strong> – it is assumed that commutation rates in the pre-reform scheme and the CARE scheme are both the maximum tax-free amounts allowable under HMRC tax rules. Please refer to HMRC guidance for further information. </li>
        </ul>
      </p>
    </Fragment>
  );


  const equalisation_info_message = (
    <Fragment>
      <h2 style = {{'color':'red'}}>Impossible to equalise lump sums!</h2>
      <p>
        It is not possible to commute enough CARE pension to yield a lump sum equal
        to the extra automatic lump sum that would have been attained if the pre-reform
        scheme had been continued. Doing so would require commuting more CARE pension
        than the 35.7% maximum. The CARE commutation percentage has therefore been set
        to this 35.7% maximum
      </p>
    </Fragment>
  );

  return (
    <div className={classes.main_container}>
      {showModal && (
        <InfoModal modalHandler={modalHandler} infoMessage={infoMessage} />
      )}
      {
        equalisation_impossible && props.commutation_percentage === 'Equal' && (
          <InfoModal 
            modalHandler={
              () => {
                set_equalisation_impossible(false)
              }
            } 
            infoMessage={equalisation_info_message} 
            />
        )
      }
      <header className={classes.header}>
        <h3>Lump Sums</h3>
        <button
          id="LumpSumSwitchHelp"
          className={classes.help_button}
          onClick={modalHandler}
        >
          Help
        </button>
      </header>
      <p>
        You can use these buttons to set additional commutation to zero or set
        lump sums to be equal in both scenarios.
      </p>
      <div className={classes.radio_container}>
        <input
          type="radio"
          id="radioNone"
          name="radioEqualiseLumpSum"
          value="None"
          onChange={() => props.set_commutation_percentage("None")}
          checked={props.commutation_percentage === "None"}
        />
        <label htmlFor="radioNone">None</label>
        <input
          type="radio"
          id="radioEqual"
          name="radioEqualiseLumpSum"
          value="Equal"
          onChange={() => props.set_commutation_percentage("Equal")}
          checked={props.commutation_percentage === "Equal"}
        />
        <label htmlFor="radioEqual">Equal</label>
        <input
          type="radio"
          id="radioMax"
          name="radioEqualiseLumpSum"
          value="Max"
          onChange={() => props.set_commutation_percentage("Max")}
          checked={props.commutation_percentage === "Max"}
        />
        <label htmlFor="radioMax">Max</label>
      </div>
    </div>
  );
};

export default LumpSumSwitch;
