import React, { Fragment, useState, useEffect, useContext } from "react";
import Input from "../components/yourdetails/inputs/Input";

import { InputDataContext } from "../shared/contexts/InputDataContext";
import statementImage from "../assets/images/accrued pension NPA service.jpg"

import StandAcc from "../assets/images/Standard/acc pen.png"
import StandSalary from "../assets/images/Standard/salary.png"
import StandService from "../assets/images/Standard/service.png"
import StandNPA from "../assets/images/Standard/service.png"

import NRDAcc from "../assets/images/NRD/acc pen.jpg"
import NRDSalary from "../assets/images/NRD/salary.jpg"

import CNPPAcc from "../assets/images/CNNP/acc pen.png"
import CNNPSalary from "../assets/images/CNNP/salary.png"
import CNNPService from "../assets/images/CNNP/service.png"
import CNNPNPA from "../assets/images/CNNP/NPA.png"


import CalculateButton from "../components/yourdetails/CalculateButton";
import classes from "./2-YourDetails.module.css";


function MyComponent(schemeName) {
  let imageSrc;
  if (schemeName === 'CNPP') {
    imageSrc = StandAcc; 
  } else if (schemeName === 'Magnox') {
    imageSrc = CNPPAcc; 
  }

  return(imageSrc)
}



function YourDetailsPage() {
  const { inputs, setInputs, valid, setValid } = useContext(InputDataContext);


  const today = new Date();
  const max_dob = today.setFullYear(today.getFullYear() - 16)
  const compareDate = new Date('2024-04-01');

  function willReachNPA(dob, npa) {

    let date_dob = new Date(dob)
    // Calculate the NPA date
    const npaDate = new Date(date_dob.setFullYear(date_dob.getFullYear() + Number(npa) ));

    // Check if the user will reach NPA after the compareDate
    return npaDate >= compareDate;
}

function calculateEarliestDOB(npa) {
  // Create a new Date based on compareDate
  let earliestDOB = new Date(compareDate.getTime());
  
  // Change the year of earliestDOB, not compareDate
  earliestDOB.setFullYear(earliestDOB.getFullYear() - npa);

  return earliestDOB;
}

function calculateLowestNPA(dob) {

  let date_dob = new Date(dob)
  // Calculate the difference in years
  const diffInYears = (compareDate - date_dob) / (1000 * 60 * 60 * 24 * 365.25);

  // Calculate the lowest possible npa
  const npa = Math.ceil(diffInYears);

  return npa;
}

function calculateHighestService(dob) {

  let date_dob = new Date(dob)
  let this_today = new Date()

  const max_age = this_today - date_dob

  const service = max_age/31556952000 - 16

  return service;
}


useEffect(
  () => {
    
    if(
      inputs.dob && 
      inputs.npa && 
      willReachNPA(inputs.dob, inputs.npa) &&
      new Date(inputs.dob) <= max_dob &&
      Number(inputs.npa) >= 55 && 
      Number(inputs.npa) <= 65
      ) {
      setValid(
        {...valid, ['dob'] : true, ['npa'] : true}
      )
    }
  }, 
  [inputs.npa, inputs.dob]
)

  return (
    <div id="Your_Details_Page">
      <h2>Tell us about your career</h2>
      <p>
        Please answer the following questions to allow us to provide you with an
        estimate of your pension benefits.
      </p>
      <Input

        input={"schemeName"}
        inputType={"select"}
        options={[{ value: "CNPP" }, { value: "Magnox" }]}
        text={"What scheme are you in?"}
        help={null}
        />
         
        
        {valid.schemeName && ( <Fragment>
          < Input
          input={"npa"}
          inputType="integer"
          text={`What is your Normal Pension Age (NPA)?`}
          help={
            <Fragment>
              <h2>Normal Pension Age</h2>
              <p>
              You can find your NPA on your benefits statement.
              </p>
              <img  
              src = {inputs.schemeName === "Magnox" ? StandNPA : CNNPNPA}
              alt = {"page from example annual benefit statement displaying where pension amount can be found"} 
              style = {{'width' : '500px', 'height' : (inputs.schemeName === "Magnox" ? "285px" : "190px")}}
              /> 
            </Fragment>
            }
          minValue={inputs.dob ? calculateLowestNPA(inputs.dob) : 55}
          maxValue={65}
          minValErrorMsg={
            inputs.dob ? 
            "Given your date of birth, your NPA cannot be below " + calculateLowestNPA(inputs.dob) :
             "Your NPA cannot be below 55"
            }
          maxValErrorMsg={"Your NPA cannot be above 65"}
        /> 
        
        <Input
          input={"dob"}
          inputType={"date"}
          text={`What is your date of birth?`}
          maxValue={max_dob}
          minValue={calculateEarliestDOB(inputs.npa)}
          minValErrorMsg={
            "This calculator does not accurately illustrate benefits for members who will be past their NPA by 1 April 2024. However, for illustration purposes you could amend the date of birth to a later date of birth – we recommend you increase your date of birth by ‘number of years you are past your NPA plus number of years you expect to continue working after 1 April 2024’. The resulting illustrations will provide an approximate comparison of pre and post reform benefits."
          }
          maxValErrorMsg={ "Your date of birth is too late"}
          help={null}
        />  </Fragment>) }

      
      {valid.schemeName && 
      valid.npa &&
      valid.dob ? (
        <Input
        input={"salaryCurrent"}
        inputType={"integer"}
        text={"What is your most recent full-time pensionable pay?"}
        help={
          <Fragment>
            <h2>Current Salary</h2>
            <p>
              This should be pensionable earnings and should be the Full-Time
              Equivalent amount. For example, if you work three days per week
              and earn £15,000 pa for those three days, then your FTE would be
              what you would earn if you worked five days, in this case
              £25,000. Please note that final pensionable pay used in this
              illustrator is based on gross pay at the point of retirement
              which differs from the actual final pensionable pay definitions
              that will be used at retirement.
            </p>
            <img  
              
              src = {inputs.schemeName === "Magnox" ? StandSalary : CNNPSalary}
              alt = {"page from example annual benefit statement displaying where pension amount can be found"} 
              style = {{'width' : '500px', 'height' : (inputs.schemeName === 'Magnox' ? '210px' : '170px')}}
              /> 
          </Fragment>
        }
        minValue={0}
        maxValue={250000}
        minValErrorMsg={"The current salary you've entered is too low."}
        maxValErrorMsg={"The current salary you've entered is too high."}
        />
      ) : null}



{valid.schemeName && 
valid.npa &&
      valid.dob &&
      valid.salaryCurrent ? (
        <Input
          input={"reckonableService"}
          inputType={"number"}
          text = {inputs.schemeName === "Magnox" ? "What is your reckonable service in the Magnox Scheme?" : "What is your reckonable service in the CNPP DB Scheme?"}

          help={
            <Fragment>
              <h2>Reckonable Service</h2>
              <p>
                This should be your reckonable service as shown in your benefit statement
              </p>
              <img  
              
              src = {inputs.schemeName === "Magnox" ? StandService : CNNPService}
              alt = {"page from example annual benefit statement displaying where pension amount can be found"} 
              style = {{'width' : '500px', 'height' : (inputs.schemeName === 'Magnox' ? '250px' : '170px')}}
              /> 
            </Fragment>
          }
          minValue = {0}
          maxValue={calculateHighestService(inputs.dob)}
          maxValErrorMsg={"The service you’ve entered is too high."}
          minValErrorMsg={"Your service cannot be below 0."}
          />
        ) : null}

{valid.schemeName && 
valid.npa &&
      valid.dob &&
      valid.salaryCurrent &&
      valid.reckonableService ? (
        <Input
          input={"accruedPension"} 
          inputType={"number"}
          text={"What is your accrued annual pension?"}
          help={
            <Fragment>
              <h2>Accrued Annual Pension</h2>
              <p>
                This should be the accrued pension as shown in your benefit statement
              </p>
              <img  
              
              src = {inputs.schemeName === "Magnox" ? StandAcc : CNPPAcc}
              alt = {"page from example annual benefit statement displaying where pension amount can be found"} 
              style = {{'width' : '500px', 'height' : (inputs.schemeName === 'Magnox' ? '330px' : '270px')}}
              /> 
            </Fragment>
          }
          minValue = {0}
          maxValue={100000}
          maxValErrorMsg={"The accrued pension you have entered is too high."}
          minValErrorMsg={"Accrued pension cannot be below 0."}
          />
        ) : null}
      

      {valid.schemeName && 
      valid.dob &&
      valid.salaryCurrent &&
      valid.npa &&
      valid.reckonableService &&
      valid.accruedPension ? (
        <div className={classes.button_container}>
          <CalculateButton />
        </div>
      ) : null}

    </div>
  );
}

export default YourDetailsPage;
