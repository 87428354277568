import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { submitInputData } from "../../shared/apis/mainAPI";
import { InputDataContext } from "../../shared/contexts/InputDataContext"; 
import { OutputDataContext } from "../../App";
import classes from "./CalculateButton.module.css";

const CalculateButton = () => {
  const [firstRender, setFirstRender] = useState(true);
  const navigate = useNavigate();
  const { inputs } = useContext(InputDataContext);

  const { outputData, setOutputData } = useContext(OutputDataContext);

  const handleSubmit = async () => {
    await submitInputData(inputs, setOutputData);
    navigate("/results")
  };

  return (
    <button className={classes.pagebutton} onClick={handleSubmit}>
      Calculate
    </button>
  );
};

export default CalculateButton;
