import React, { Fragment, useContext, useEffect, useState } from "react";
import PensionBarChart from "../components/results/charts/PensionBarChart";
import LumpSumBarChart from "../components/results/charts/LumpSumBarChart";
import SummaryHeader from "../components/results/SummaryHeader";
import RetirementAgeSwtich from "../components/results/switches/RetirementAgeSwitch";
import InflationSwitch from "../components/results/switches/InflationSwitch";
import LumpSumSwitch from "../components/results/switches/LumpSumSwitch";
import SalarySlider from "../components/results/switches/SalarySlider";
import ComparisonTable from "../components/results/ComparisonTable";
import NuvosWarning from "../components/results/NuvosWarning";
import classes from "./3-Results.module.css";
import { OutputDataContext } from "../App";
import { submitInputData } from "../shared/apis/mainAPI";
import { InputDataContext } from "../shared/contexts/InputDataContext";
import { propsToClassKey } from "@mui/styles";
import HSTable from "../components/results/HSTable";

function ResultsPage() {



  const { inputs, setInputs } = useContext(InputDataContext);
  const { outputData, setOutputData } = useContext(OutputDataContext);
  console.log('CP', inputs.commutation_percentage)
  const [commutation_percentage, set_commutation_percentage] = useState(inputs['commutation percentage']);

  let showEqualiseSwitch = true



  useEffect(
    () =>
    {setInputs({ ...inputs, ['commutation percentage']: commutation_percentage })}, 
    [commutation_percentage]
  )

  useEffect(() => {
    submitInputData(inputs, setOutputData);
  }, [inputs]);





  return (
    <div id="Results_Page">
      <h2>Your Illustrated Results</h2>
      <p>
      The following page provides a simple illustration of 
      the annual pension and lump sum that you may receive
       at your normal pension age, both before and after the reforms. 
       The figures below are in real terms.
      </p>
      <SummaryHeader
        showLumpSums={true}
        legacySchemeName={'Legacy'}
        remedySchemeName={'CARE'}
        data={outputData}
      />

        <Fragment>
          <div className={classes.chart_container}>
            <div className={classes.chart}>
              <PensionBarChart
                key={1}
                data = {outputData}
              />
            </div>
            <div className={classes.chart}>
              <LumpSumBarChart
                key={2}
                data = {outputData}
              />
            </div>
          </div>
          <div className={classes.switch_container}>

            {showEqualiseSwitch && (
              <LumpSumSwitch
                set_commutation_percentage={set_commutation_percentage}
                commutation_percentage={commutation_percentage}
                commutation_value={outputData.CARE_comm_perc}
                equalisation_outcome ={outputData['equalisation outcome']}
              />
            )}
            
            <HSTable
            comm_perc = {
              {'CARE': String(outputData.CARE_comm_perc) + '%',
               'Legacy full': String(outputData.legacy_full_comm_perc) + '%',
               'Legacy curtailed' : String(outputData.legacy_curtailed_comm_perc) + '%'
              }
            }
            legacy_commutation_factor = {outputData.legacy_commutation_factor}
            />
          

          </div>
        </Fragment>
      
      
      <ComparisonTable
        data={outputData}
      />
    </div>
  );
}

export default ResultsPage;
