import React, { Fragment } from "react";
import { currenyFormatter, sumValues } from "../../shared/utilities/Formatters";
import classes from "./ComparisonTable.module.css";
import params from "../../shared/utilities/global_parameters";

const ComparisonTable = (props) => {
  return (
    <div className={classes.container}>
      <h2>Your Comparison Table</h2>
      <p>
        You can use this table to compare the expected benefits in the scenarios
        outlined above. The figures below are in real terms.
      </p>
      <table className={classes.comp_table}>
        <thead>
          <tr>

            <td>Scenario</td>
            
            <td>
              Pre-reform scheme pension
            </td>
            <td>Care pension</td>
            <td>Total Pension</td>
            <td> Pre-reform scheme Lump Sum </td>
            <td> CARE Lump Sum </td>
            <td> Total Lump Sum </td>
          </tr>
        </thead>
        <tbody>
          <tr>

            <td>Before reforms</td>
            
            <td>
              {currenyFormatter(props.data['LP full'])}
            </td>
            <td>{currenyFormatter(0)}</td>
            <td>{currenyFormatter(props.data['LP full'])}</td>
            <td> {currenyFormatter(props.data['LS legacy full'])} </td>
            <td> {currenyFormatter(0)} </td>
            <td> {currenyFormatter(props.data['LS legacy full'])} </td>
          </tr>
          <tr>

            <td>After reforms</td>
            
            <td>
              {currenyFormatter(props.data['LP curtailed'])}
            </td>
            <td>{currenyFormatter(props.data['CARE'])}</td>
            <td>{currenyFormatter(props.data['LP curtailed'] + props.data['CARE'])}</td>
            <td> {currenyFormatter(props.data['LS legacy curtailed'])} </td>
            <td> {currenyFormatter(props.data['LS CARE'])} </td>
            <td> {currenyFormatter(props.data['LS legacy curtailed'] + props.data['LS CARE'])} </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ComparisonTable;
