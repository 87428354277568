import React, { Fragment } from "react";
import { currenyFormatter, sumValues } from "../../shared/utilities/Formatters";
import classes from "./SummaryHeader.module.css";

const SummaryHeader = (props) => {
  let totalReformedPension = currenyFormatter(
    sumValues([props.data['CARE'], props.data['LP curtailed']])
  );
  let reformedLumpSum = currenyFormatter(
    sumValues([props.data['LS legacy curtailed'], props.data['LS CARE']])
  );

  let totalLegacyPension = currenyFormatter(
    sumValues([props.data['LP full']])
  );
  let legacyLumpSum = currenyFormatter(
    sumValues([props.data['LS legacy full'] ])
  );

  return (
    <div className={classes.container}>
      {props.showLumpSums && (
        <Fragment>
          <h3>Before reforms</h3>
          <section className={classes.section}>
            <div>
              <p>
                Your estimated gross annual pension if you retire at your
                Normal Pension Age is:
              </p>
              <h2>{totalLegacyPension}</h2>
            </div>
            <div>
              <p>
                Your estimated cash lump sum if you retire at your
                Normal Pension Age is:
              </p>
              <h2>{legacyLumpSum}</h2>
            </div>
          </section>
          <h3>After reforms</h3>
          <section className={classes.section}>
            <div>
              <p>
              Your estimated gross annual pension if you retire at your
                Normal Pension Age is:
              </p>
              <h2>{totalReformedPension}</h2>
            </div>
            <div>
              <p>
              Your estimated cash lump sum if you retire at your
                Normal Pension Age is:
              </p>
              <h2>{reformedLumpSum}</h2>
            </div>
          </section>
        </Fragment>
      )}
      
      
    </div>
  );
};

export default SummaryHeader;
