import React from "react";
import NavBar from "./NavBar";
import stock_photo from "../../assets/images/stock_photo.jpg";
import params from "../../shared/utilities/global_parameters";
import classes from "./Header.module.css";


  const Header = () => {
  
  return (
    <header className={classes.headerContainer}>

      <div className={classes.overlay}></div>
      <div className="textContainer">
      <p className="custom-text">The NDA group</p>
      <p className="custom-text">Pension Reform</p>
    </div>
   
      <div className={classes.mainHeader}>
        <h1 className={classes.pageTitle}>{params.app_name_long}</h1>
        <NavBar />
      </div>
      <div
        className={classes.backgroundImage}
        style={{ backgroundImage: `url(${stock_photo})` }}
      ></div>
      <div className={classes.imgContainer}>
      
      </div>
    </header>
  );
};

export default Header;
