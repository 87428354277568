import React, { useState, useEffect, useContext } from "react";
import { currenyFormatter } from "../../../shared/utilities/Formatters";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from "chart.js"; 
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import params from "../../../shared/utilities/global_parameters";
import { InputDataContext } from "../../../shared/contexts/InputDataContext";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle
);



function BarChart(props) {


  const [colors, setColors] = useState({});  

  // This will run after the initial render
  useEffect(() => {
    const rootStyle = getComputedStyle(document.getElementById('root'));
    setColors({
      'full_legacy': rootStyle.getPropertyValue('--full-legacy').trim(),
      'curtailed_legacy': rootStyle.getPropertyValue('--legacy-curtailed').trim(),
      'CARE': rootStyle.getPropertyValue('--CARE').trim(),
    });
  }, []);


  const titleFontSize = 20;
  const normalFontSize = 15;
  const fontColor = "rgb(0,0,0)";
  const [chartOptions, setChartOptions] = useState(null)

  const { inputs, setInputs, valid, setValid } = useContext(InputDataContext);

  useEffect(
    () => {
      if (Object.values(colors).some(color => !color)) return;
      setChartOptions({
      Chart: { defaults: { color: [fontColor] } },
      indexAxis: "x",
      responsive: true,
      maintainAspectRatio: false,
      animation: true,
      // hover: null,
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            font: {
              size: normalFontSize,
            },
            color: [fontColor],
          },
          onClick: false,
        },
        title: {
          display: true,
          text: "Estimated Gross Annual Lump sum at " + inputs.npa,
          font: {
            weight: "bold",
            size: titleFontSize,
          },
          padding: {
            bottom: 1,
          },
          color: [fontColor],
        },
        subtitle: {
          display: true,
          text: "(Values in real terms)",
          font: {
            // weight: "bold",
            size: normalFontSize,
          },
          padding: {
            bottom: 20,
          },
          color: [fontColor],
        },
        interation: {
          mode: "y",
        },
        tooltip: {
          backgroundColor: "white",
          borderColor: "black",
          borderWidth: 1,
          titleColor: fontColor,
          titleFont: {
            size: normalFontSize,
          },
          bodyColor: fontColor,
          bodyFont: {
            size: normalFontSize,
          },
          yAlign: "center",
          callbacks: {
            title: function (context) {
              return context[0].label.replaceAll(",", " ");
            },
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              if (context.parsed.x !== null) {
                label += currenyFormatter(context.parsed.y);
              }
              return label;
            },
          },
        },
        datalabels: {
          anchor: "end",
          align: "top",
          formatter: (value, context) => {
            const datasetArray = [];
            context.chart.data.datasets.forEach((dataset) => {
              if (dataset.data[context.dataIndex] !== undefined) {
                datasetArray.push(dataset.data[context.dataIndex]);
              }
            });
            function totalSum(total, datapoint) {
              return total + datapoint;
            }
            let sum = datasetArray.reduce(totalSum, 0);
            if (context.datasetIndex === datasetArray.length - 1) {
              return "Total Lump Sum: " + currenyFormatter(sum);
            } else {
              return "";
            }
          },
          font: {
            size: normalFontSize,
          },
          color: [fontColor],
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: "Value in real terms",
            font: {
              size: normalFontSize * 1.2,
            },
            color: fontColor,
          },
          stacked: true,
          grid: { display: false },
          ticks: {
            callback: function (value) {
              return currenyFormatter(value);
            },
            font: {
              size: normalFontSize,
            },
            color: [fontColor],
          },
          // grace: 1000,
        },
        x: {
          stacked: true,
          grid: { display: false },
          ticks: {
            font: {
              size: normalFontSize,
            },
            color: [fontColor],
          },
        },
      },
    })
}, [
    props.data,
  ]);


  const chartData = {
    labels: ['Before reforms', 'After reforms'],
    datasets: [
      {
        label: 'Final salary lump sum before reforms',
        data: [props.data['LS legacy full'], 0],
        backgroundColor: colors['full_legacy'],
      },
      {
        label: 'Final salary lump sum after reforms',
        data: [0, props.data['LS legacy curtailed']],
        backgroundColor: colors['curtailed_legacy'],
      },
      {
        label: 'CARE lump sum after reforms',
        data: [0, props.data['LS CARE']],
        backgroundColor: colors['CARE'],
      },
    ],
  }
  return (
    <Bar options={chartOptions} data={chartData} plugins={[ChartDataLabels]} />
  );
}

export default BarChart;
