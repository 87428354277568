export const submitInputData = async (inputs, setOutputData) => {
  let address =
    (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "/api/setup";

  const InputData = { 
    dob: inputs.dob,
    NPA : inputs.npa,
    currentSalary: inputs.salaryCurrent,
    ['reckonable service'] : inputs.reckonableService, 
    ['commutation percentage'] : (inputs['commutation percentage'] ? inputs['commutation percentage'] : 'default'), 
    accruedPension : inputs.accruedPension, 
    schemeName : inputs.schemeName
  };

  

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(InputData),
  };

  try {
    const response = await fetch(address, requestOptions);
    const data = await response.json();
   
    setOutputData(data);
  } catch (error) {
    console.log(error);
  }
};
