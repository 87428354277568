import classes from "./HSTable.module.css";
import React,  {Fragment, useState} from 'react';
import poundLogo from "../../assets/images/pound circle.png"
import poundHand from "../../assets/images/pound hand.png"
import InfoModal from "../../components/general/InfoModal";

const HSTable = (props) => {
const [show_modal, set_show_modal] = useState('')
    const gridData = [
        { text: [
            <Fragment key = {3}> 
            <p> Commutation Rate  </p> 
            <p> {String(props.comm_perc['CARE'])} </p>
            </Fragment>
        ] , img: poundLogo },
        { text: [
            <Fragment key = {4}> 
            <p> Commutation Rate  </p> 
            {props.comm_perc['Legacy full'] === props.comm_perc['Legacy curtailed'] ? 
            <p> {String(props.comm_perc['Legacy full'])} </p> :
            <Fragment> 
                <p style = {{'margin' : '0'}}> Before reforms: {String(props.comm_perc['Legacy full'])} </p>
                <div style = {{position : 'absolute', 'bottom' : '5px', 'left' : '0', 'width' : '100%'}}>
                    <p> After reforms: {String(props.comm_perc['Legacy curtailed'])}</p>
                </div>
            </Fragment>
        }
            
            </Fragment>
        ], img: poundLogo},
        { text: [
            <Fragment key = {1}> 
            <p> Commutation Factor  </p> 
            <p> 12 </p>
            </Fragment>
        ], img: poundHand },
        { text: [
            <Fragment key = {2}> 
            <p> Commutation Factor  </p> 
            <p> {String(props.legacy_commutation_factor)} </p>
            </Fragment>
        ], img: poundHand }
    ];

    const explanations = [
      'This is the proportion of CARE pension that has been exchanged (commuted) for a one-off lump sum.', 
      'This is the proportion of pre-reform scheme pension that has been exchanged (commuted) for a one-off lump sum.', 
      'This is the amount of lump sum you will receive for every £1 of annual pension commuted from the CARE scheme.', 
      'This is the amount of lump sum you will receive for every £1 of annual pension commuted from the pre-reform scheme. \
      This calculator uses the commutation factors in effect at time of development. However, commutation factors are reviewed \
      periodically and the amount of cash lump sum available at your actual retirement will depend on the commutation factors in \
      effect at that time.'
    ]

    return (
      <div className = {classes.container}>  
       {show_modal !== '' && <InfoModal modalHandler={() => set_show_modal('')} infoMessage={ 
        <Fragment>
        <h2> Title </h2>
        <p> {show_modal} </p>
        </Fragment>
        } /> }
        <div className={classes.grid}>
            <div className = {classes.title_container}> 
                <div className={classes.title} style={{ gridColumn: 'span 2', textAlign: 'center' }}>CARE</div>
                <div className={classes.title} style={{ gridColumn: 'span 2', textAlign: 'center' }}>Pre-reform</div>
            </div>
            {gridData.map((item, index) => (
                <div 
                key={index} className={classes.cell + ' ' + classes.overwrite}
                style = {
                  {'--down' : index > 1 ? -1 : 1,
                   '--right' : (index % 2) === 0 ? 1 : -1, 
                   '--col' : (index % 2) === 0 ?   'rgb(0, 127, 163, 0.6)' : 'rgb(0, 127, 163, 1)' ,
                   '--lighter' : (index % 2) === 0 ?  'rgb(0, 127, 163, 0.2)' : 'rgb(0, 175, 215, 0.4)',
                   'position' : 'relative'
                  }
                   }> 
                   <button 
                   className = {classes.help_button}
                   
                   onClick = {() => {set_show_modal(explanations[index])}}> Help </button>
                   
                    <img src={item.img} alt="placeholder" className={classes.image} />
                    <div className={classes.text}>{item.text}</div>
                </div>
            ))}
        </div>
        </div>
    );
}

export default HSTable;

