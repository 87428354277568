import React from "react";
import { Link } from "react-router-dom";
import params from "../../shared/utilities/global_parameters";

const FrontPageQuestionSelectorText = [
  {
    id: "FAQs-Q1",
    question: `What does this illustrator do?`,
    answer: (
      <p>
        The NDA Pension Reform Illustrator is designed to provide a basic illustration of projected 
        service benefits at your retirement following the reform to NDA pension schemes. 
        It also provides a comparative illustration against the benefits that would have been received 
        had the reforms not been introduced. Please refer to the guidance.
      </p>
    ),
  },
  {
    id: "FAQs-Q2",
    question: `What information do I need to complete the ${params.app_name_short}?`,
    answer: (
      <div>
        <p>
        We will ask you for information including: your pension scheme which is 
        being reformed, your Normal Pension Age, your current pensionable pay
        (the annual amount before tax) and your total reckonable service.
        </p>
        <p>
        It will be really handy if you have a copy of 
        your latest annual benefit statement available. Information on your payslip may also assist. 
        In most cases an estimate is fine if not available.
        </p>
      </div>
    ),
  },

  {
    id: "FAQs-Q4",
    question: "Which schemes are being reformed?",
    answer: (
      <p>
        The schemes being reformed are the Combined Nuclear Pension Plan (CPS Section), Combined Nuclear Pension Plan (GPS Section) and Magnox Electric Group of the Electricity Supply Pension Scheme.
      </p>
    ),
  },
  
];

export default FrontPageQuestionSelectorText;
